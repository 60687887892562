@import DESIGN

.old-demo-request-form-main
    .request-demo-column
        background-color white
        border 1px solid #f2f1f3
        border-radius 4px
        box-shadow 0 2px 5px rgba(25,25,25,.2)
        padding-top 9px
        padding-bottom 30px
        width 100%
        margin-left 0px

        .form-group
            text-align left

        .request-demo-text
            padding-bottom 0px

            h1, h3
                font-weight 300

            .request-demo-main-header
                font-size 40px
                font-weight 300
                color QuorumBlue

            .section-lead-header
                color RequestDemoHoverPurple

            .request-demo-text-main
                font-size 25px
                font-weight 300
                color QuorumBlue
                padding-bottom 15px

        .request-demo-form-column
            border-top 1px solid #eee
            padding-top 30px
            padding-left 0px
            padding-right 0px

            .request-demo-submit
                margin-top 15px
                display inline-block
                background-color RequestDemoPurple
                border-color RequestDemoBorderPurple
                color white
                font-weight 400
                font-size 22px

                &:hover
                    background-color RequestDemoHoverPurple

            .request-demo-form
                // each of the inputs is a col-md-x
                // that is wrapped in a div
                // we need to select the children and
                // then make sure they have appropriate
                // padding in both two column
                // and one column orientations
                div
                    div[class^="col-"]
                        padding 0px

                @media (min-width 992px)
                    div

                        &:nth-child(odd)
                            div[class^="col-"]
                                padding-right 10px

                        &:nth-child(even)
                            div[class^="col-"]
                                padding-left 10px

                        &:last-child
                            div[class^="col-"]
                                padding 0px !important

                textarea
                    resize none

                .form-control
                    border-top-right-radius 4px !important
                    border-bottom-right-radius 4px !important

                .input-group-addon
                    background-color DarkPurple

                    i
                        color white
