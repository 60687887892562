@import '../../../../../app/static/stylus/DESIGN.styl'

FormWidth = 400px
FormHeight = 377px
SmallerPadding = 20px
DefaultFontSize = 18px
DarkSlateGrey = #2f4f4f
DefaultFontWeight = 300
DefaultBorderRadius = 5px
ShakeAnimationDuration = 0.75s
ShakeIterationCount = 0.4
SlideAnimationDuration = 0.3s
IconSize = 24px

.login-form-main
    background-color white
    box-shadow 0 1px 12px DarkSlateGrey
    border-radius DefaultBorderRadius
    min-height FormHeight
    min-width FormWidth
    max-width FormWidth
    text-align center
    // Overflow and direction needed for sliding animation
    direction rtl
    overflow-x hidden
    // Shaking animation
    animation-fill-mode backwards
    animation-duration ShakeAnimationDuration
    -webkit-animation-duration ShakeAnimationDuration
    -moz-animation-duration ShakeAnimationDuration
    animation-iteration-count ShakeIterationCount
    -webkit-animation-iteration-count ShakeIterationCount
    -moz-animation-iteration-count ShakeIterationCount

    @media (max-width: LoginMobileWidth)
        min-width 90%
        margin-left SmallerPadding
        margin-right SmallerPadding

    .login-form-logo-wrapper
        border-bottom 1px solid QuorumGrey5
        display block
        background-color #fbfbfc
        margin-bottom SmallerPadding

        .login-form-logo
            padding-top SmallerPadding
            padding-bottom SmallerPadding
            height 100px
            width auto

    .login-form-content
        padding-right SmallerPadding
        padding-left SmallerPadding
        // change direction back to normal for text
        direction ltr

        .login-form-field
            .login-form-password-strength
                margin-bottom 20px

        .login-form-banner-message
            font-weight DefaultFontWeight
            margin-bottom SmallerPadding
            padding 10px
            text-align center
            background-color DarkPurple
            border 1px solid darken(DarkPurple, 10%)
            color white
            font-size DefaultFontSize
            border-radius 5px

        .login-form-error-message
            background-color MiddlePurple
            border 1px solid darken(MiddlePurple, 10%)

        .animated
            animation-duration SlideAnimationDuration
            -webkit-animation-duration SlideAnimationDuration
            -moz-animation-duration SlideAnimationDuration

        .form-group
            margin-bottom SmallerPadding

            .input-group
                width 100%

                .form-control
                    height 45px
                    text-align center
                    font-size DefaultFontSize
                    border-radius DefaultBorderRadius
                    font-weight DefaultFontWeight
                    border 1px solid QuorumBlue

                    &:focus
                        box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px QuorumBlueRGBA(.6)

        .login-form-button-group
            display flex
            justify-content space-between

            .btn
                background-color OtherColor2
                margin-bottom SmallerPadding
                font-size DefaultFontSize
                font-weight DefaultFontWeight

                &:hover
                    background-color darken(OtherColor2, 15%)

                &:focus
                    outline none

                .loading-div
                    position relative

                    .loading-icon-right
                        i
                            margin-right 0px

                        &:not(.is-loading)
                            font-size IconSize
                            position absolute
                            right 0
                            top -6px

            .btn.login-form-multiple-button
                width 68%

            .login-form-back-button
                color QuorumBlue
                border 1px solid QuorumBlue
                background-color white
                width 28%

                &:hover
                    background-color QuorumGrey5

                .fa-angle-left
                    font-size IconSize
                    float left
                    position relative
                    bottom 1px

        .login-form-links
            margin-top -5px
            margin-bottom 15px

            .login-form-forgot
                font-size 16px
                color MiddlePurple
                cursor pointer
                font-weight DefaultFontWeight

                &:hover
                    color LightPurple
