@import DESIGN

.email-request-form-wrapper

    background #d5d9df

.email-request-form
    input
        font-size 20px !important

    &.hero-image
        .form-group
            max-width 750px

    .form-group
        margin-bottom 0px

        .form-control
            border 1px solid QuorumBlue

    .input-group-addon
        transition background-color 0.2s ease-out
        -o-transition background-color .2s ease-out
        -ms-transition background-color .2s ease-out
        -moz-transition background-color .2s ease-out
        -webkit-transition background-color .2s ease-out

        background-color QuorumBlue
        border-color #3e507b
        color white
        font-size 18px
        cursor pointer
        font-weight 300
        user-select none

.email-signup-button-group
    margin-top 5px
    text-align center
    display flex
    width calc(100% + 10px)
    position relative
    right 5px
    flex-wrap wrap

    .email-signup-toggle-buttons
        flex-grow 1
        font-size 17px
        display inline-block
        position relative
        padding 5px
        text-align left
        padding-left 15px
        background-color white
        border-radius 4px
        margin 5px
        cursor pointer
        color QuorumBlue
        font-weight 400
        border 1px solid QuorumBlue
        user-select none

        &:hover
            background-color QuorumGrey5

        @media screen and (max-width: 1310px)
            width calc(100% * (1/2) - 10px - 1px)

        @media screen and (max-width: 570px)
            width calc(100% + 10px)

        .fa
            position absolute
            right 10px
            font-size 20px
            top 8px

.footer-email-request
    .form-group
        margin-bottom 0px

        .form-control
            border 1px solid QuorumBlue

        .input-group-addon
            transition background-color 0.2s ease-out
            -o-transition background-color .2s ease-out
            -ms-transition background-color .2s ease-out
            -moz-transition background-color .2s ease-out
            -webkit-transition background-color .2s ease-out

            background-color QuorumBlue
            border-color #3e507b
            color white
            font-size 14px
            cursor pointer
            font-weight 300
            user-select none

    .email-signup-button-group
        .email-signup-toggle-buttons
            font-size 13px
            width calc(100% * (1/2) - 10px - 1px)

            @media screen and (min-width: 992px) and (max-width: 1380px)
                width calc(100% + 10px)

            @media screen and (max-width: 470px)
                width calc(100% + 10px)

        .fa
            font-size 15px
            right 5px
            top 7px
