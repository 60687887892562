@import '../../../../../app/static/stylus/DESIGN.styl'
MobilePadding = 30px

.resources-page-main

    .resources-filter-mobile
        padding-right 45px
        padding-left 45px

        .resources-filter-dropdown
            margin-top MobilePadding
            font-size 18px
            font-weight 300
            background-color QuorumBlue
            color white

            .caret
                margin-left 5px
                border-width 7px

            &:hover, &:active, &:visited
                background-color #d4d4d4
                color black

            @media (max-width: 767px)
                margin-top 15px

        .dropdown-menu
            min-width 100%

            .resources-filter-menu-item
                font-size 18px

                a
                    font-weight 300

    .resources-page-search
        margin-top 30px

        @media (max-width:767px)
            margin-top 20px
            padding-left MobilePadding
            padding-right MobilePadding

        .form-control
            font-size 18px
            padding 10px 15px
            font-weight 300
            height 42px

        .input-group-addon
            background-color QuorumBlue
            border-color #3e507b
            color white
            font-size 18px
            font-weight 300
            cursor pointer

            &:hover
                background-color rgba(48, 62, 96, 0.9)

    .row.resources-page-content-wrapper
        margin 15px 15px

        @media (max-width:767px)
            margin-top 0px

        .resources-page-content
            .resources-page-loading
                font-size 50px
                text-align center

    .resources-page-button-group
        text-align center
        margin-top 20px

        .active
            background-color MiddlePurple

        .resources-page-button
            margin 10px 5px 0px 5px
            font-size 18px
            padding 10px 15px
            text-decoration none

    .btn.resources-page-next-button
        margin-top 25px
        margin-bottom 55px
        font-size 25px
        padding 17px 16px

    .resources-page-button, .btn.resources-page-next-button
        font-weight 300
        line-height 1.3333333
        border-radius 6px

        display inline-block
        text-align center
        white-space nowrap
        vertical-align middle
        cursor pointer

        background-color QuorumBlue
        color white

        &:active, &:focus, &:hover
            background-color MiddlePurple !important
            color white !important

        &:focus
            outline none !important


    .email-request-form-wrapper
        .email-request-form
            margin-top 24px
            margin-bottom 50px

            .email-signup-header
                margin-top 0px
                margin-bottom 25px
                color QuorumBlue
                font-weight 300
                font-size 35px
                text-align center
