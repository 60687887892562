.read-more
    margin-top 20px
    font-weight 300
    font-size 20px

    a
        color #854EA4
        cursor pointer

        &:hover,
        &:focus
            color #303e60
            text-decoration none !important

        i
            font-size 15px