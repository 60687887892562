@import DESIGN

.hero-img
    width 100%
    margin-top -20px
    display block
    height 450px

    &.full-header-request-form
        min-height calc(100vh + 20px)
        height 100%

    .hero-img-video-wrapper
        position absolute
        width inherit
        height inherit
        overflow hidden

        .hero-img-video
            width inherit
            position relative
            bottom calc(185px - 1vw)

        @media (max-width: 992px)
            display none

    .hero-img-container
        width 100%
        height 100%
        background rgba(48, 62, 96, 0.8)
        display table
        position relative

        &.full-header-request-form
            min-height calc(100vh + 20px)

        @media(max-width: MobileWidth)
            &.full-header-request-form
                background QuorumBlueRGBA(0.85)

        @media(min-width: MobileWidth)
            &.full-header-request-form
                background linear-gradient(to right, QuorumBlueRGBA(1), QuorumBlueRGBA(0.8) 100%)

        .hero-img-centered-content
            display table-cell
            vertical-align middle
            text-align center
            color white
            padding-top 80px

            .hero-img-centered-content-row
                position relative
                bottom 25px

                .hero-img-content-items-wrapper
                    @media(min-width: MobileWidth)
                        &.full-header-request-form
                            margin-top auto
                            margin-bottom auto

                    @media(max-width: MobileWidth)
                        &.full-header-request-form
                            margin-bottom 65px
                            margin-top 15px

                            &.blog-download-content-form
                                margin-bottom 0px

                    .hero-img-content-items-row
                        @media(min-width: MobileWidth)
                            &.full-header-request-form
                                display flex
                                justify-content space-between
                                align-items center

                    .hero-img-content-items
                        .title
                            font-size 50px
                            line-height 65px
                            text-align left
                            margin-top 40px
                            font-weight 350

                            @media (max-width: MobileWidth)
                                text-align center

                            @media (max-width: SmallMobileWidth)
                                font-size 30px
                                line-height 45px
                                padding-left 30px
                                padding-right 30px
                                margin-top 20px

                        @media(min-width: MobileWidth)
                            &.full-header-request-form
                                .title
                                    font-size 50px
                                    margin-top 0px
                                    margin-bottom 30px

                        .subtitle
                            font-size 25px
                            line-height 30px
                            margin-top 15px
                            text-align left
                            font-weight 300

                            @media (min-width: MobileWidth)
                                &:not(.full-header-request-form)
                                    width 60%

                            @media (max-width: SmallMobileWidth)
                                text-align center
                                font-size 20px
                                line-height 25px
                                padding-left 30px
                                padding-right 30px
                                width 100%

                        .checklist-wrapper
                            text-align left
                            margin-top 30px

                            @media (max-width: MobileWidth)
                                display none

                            .checklist-header
                                font-size 25px
                                font-weight 400 !important

                            .checklist-items-wrapper
                                margin-top 10px
                                font-size 20px

                                .checklist-item
                                    .fa
                                        margin-right 10px

                        .email-request-form
                            margin-top 33px

                            @media (max-width: SmallMobileWidth)
                                padding-right 30px
                                padding-left 30px

                            input:not(.email-signup)
                                border-color EmailSignupBorder

                            .input-group-addon:not(.email-signup)
                                font-size 22px
                                padding-left 18px
                                padding-right 18px
                                background RequestDemoPurple
                                border-color EmailSignupBorder
                                font-weight 400 !important

                                &:hover
                                    background RequestDemoHoverPurple
                                    border-color EmailSignupBorder

                            .form-group
                                max-width 675px

                            @media (max-width: 536px)
                                .input-group
                                    width 100%

                                input
                                    display table-row
                                    border-radius 4px
                                    margin-bottom 10px

                                .input-group-addon
                                    display table
                                    width 100%
                                    height 56px
                                    border 1px solid RequestDemoBorderPurple !important
                                    border-radius 4px !important
                                    padding 15px

                                h2
                                    padding-left 15px
                                    padding-right 15px

                        .mobile-cta-wrapper
                            text-align left

                            @media (max-width: SmallMobileWidth)
                                text-align center

                            @media (min-width: 992px)
                                margin-top 20px

                            .mobile-cta-app-store, .mobile-cta-google-play
                                height 45px
                                margin 10px
                                border 1px solid white
                                border-radius 10px
                                cursor pointer

                            .mobile-cta-column
                                padding-left 5px

                                @media (max-width: SmallMobileWidth)
                                    padding-left 15px

                        .hero-image-logo
                            height 60px
                            width auto
                            background-size contain
                            background-repeat no-repeat
                            margin-top 5px
