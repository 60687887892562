@import '../../../../../app/static/stylus/DESIGN.styl'

.addons-main
    background-color QuorumGrey1
    padding-top 30px
    padding-bottom 35px
    margin-bottom 55px

    .addons-title
        text-align center
        margin-top 0px
        margin-bottom 5px

    .addons-description
        font-size 25px
        text-align center
        margin-top 10px
        margin-bottom 35px
