@import DESIGN

White = #FFFFFF

NavFontSize = 18px
NavFontWeight = 300
RoundedBorderRadius = 4px !important
NoBorderRadius = 0px !important

TransparentBlue = rgba(48, 62, 96, 0.75)
TransparentBlueHover = rgba(61,50,98,0.99) !important;

LessTransparentBlue = rgba(48, 62, 96, 0.95)
LessTransparentWhite = rgba(255,255,255,.92)


.navigation-main
    transition-duration 0.3s

    .navbar-default
        background-color white

    .btn, a
        transition background-color 0.2s ease-out
        -o-transition background-color .2s ease-out
        -ms-transition background-color .2s ease-out
        -moz-transition background-color .2s ease-out
        -webkit-transition background-color .2s ease-out

    .navbar-brand
        .logo-container
            width 190px

    .navbar-nav
        .dropdown-toggle
            font-size NavFontSize
            font-weight NavFontWeight
            border-radius 6px

    .navbar-form
        margin-left -15px

        .btn-lg
            font-size NavFontSize
            font-weight NavFontWeight

            &:active
                color white

    .request-demo-btn
        background-color RequestDemoPurple
        color White
        border 1px solid RequestDemoBorderPurple
        margin-right 15px
        font-weight 400 !important

        &:hover, &:focus
            background-color RequestDemoHoverBackgroundColor
            border 1px solid RequestDemoHoverBackgroundColor
            color White

    .login-btn
        background-color LessTransparentBlue
        color White
        border 1px solid LessTransparentBlue

        &:hover, &:focus
            background-color TransparentBlueHover
            border 1px solid TransparentBlueHover
            color White


    .dropdown-menu
        margin-right 10px

        a
            font-weight 300 !important

            &:focus
                outline none

    .dropdown
        @media (max-width: NavBarBreakPoint) and (min-width: SmallMobileWidth)
            .dropdown-toggle
                font-size 17px

    .dropdown.no-items
        .caret
            display none

        .dropdown-menu
            display none !important

@media (min-width: SmallMobileWidth)
    // (after scrolling)
    // When the nav is not affixed to the top of the screen, and not in mobile
    .navigation-main
        border none
        background none
        box-shadow none
        padding-top 15px
        padding-bottom 15px

        &.affix
            padding-top 5px
            padding-bottom 5px
            // background rgba(255,255,255,0.9)
            background White
            box-shadow 0 4px 6px rgba(75,75,75,.2)

            .logo-text
                fill #303e60

            .navbar-brand
                .logo-container
                    margin-top -11.5px

            .dropdown
                &.open
                    a.dropdown-toggle
                        color black

        .navbar-header
            height 62px

@media (min-width: SmallMobileWidth)
    // (before scrolling)
    // When the nav is fixed to the top of the screen, and not in mobile
    .navigation-main
        &.affix-top
            border none
            background none
            box-shadow none
            padding-top 15px
            padding-bottom 15px

            .navbar-brand
                .logo-container
                    margin-top -17.5px

                    .logo-text
                        fill White

            .dropdown
                a.dropdown-toggle
                    color White

                &.open
                    a.dropdown-toggle
                        color White

            .request-demo-btn
                display none

            /* make the login button inside of the transparent section less obvious */
            .login-btn:not(:hover)
                background transparent !important
                border-color #ffffff7a

@media (min-width: SmallMobileWidth)
    // global style rules to be excluded in mobile nav
    .container
        padding-left 0px
        padding-right 0px

        .navbar-brand
            padding-right 0px
            padding-left 0px
            padding-bottom 0px
            padding-top 15px

    .navbar-nav
        padding-top 8px

        .dropdown
            padding 0px 10px 5px 0px

            &.open
                a.dropdown-toggle
                    background-color transparent !important

            &.open:hover
                a.dropdown-toggle
                    background-color LessTransparentBlue

        li
            a.dropdown-toggle
                padding-top 13px
                padding-bottom 13px

            a.navbar-btn
                padding-top 10.5px
                padding-bottom 10.5px
                margin-top 10px

            &:hover:not(:focus)
                a.dropdown-toggle
                    background-color LessTransparentBlue !important
                    color White !important

            &.dropdown.open
                .dropdown-menu
                    display None

            &.dropdown:hover
                .dropdown-menu
                    display block
                    border-top-left-radius RoundedBorderRadius
                    border-top-right-radius RoundedBorderRadius
                    padding 0 !important

                    li:last-child
                        border-bottom: none !important

                    li:first-child a,
                    li:first-child a:hover,
                    &:active li:first-child a
                        border-top-left-radius RoundedBorderRadius
                        border-top-right-radius RoundedBorderRadius
                        border-bottom-left-radius NoBorderRadius
                        border-bottom-right-radius NoBorderRadius

                    li a,
                    li a:hover,
                    &.active li a:hover
                        border-radius: NoBorderRadius

                    li:last-child a,
                    li:last-child a:hover,
                    &.active li:last-child a:hover
                        border-bottom-left-radius RoundedBorderRadius
                        border-bottom-right-radius RoundedBorderRadius
                        border-top-left-radius NoBorderRadius
                        border-top-right-radius NoBorderRadius

                    li a
                        color #2c343b !important
                        padding 10px !important
                        width 100% !important
                        font-size NavFontSize !important
                        font-weight NavFontWeight
                        text-align left !important
                        background-color LessTransparentWhite !important

                    li a:hover
                        background-color LessTransparentBlue !important
                        color #fff !important
                        border-radius 0px !important

@media (max-width: SmallMobileWidth)
    // Mobile Specific navigation
    .navigation-main
        padding-top 15px !important
        padding-bottom 0px !important
        box-shadow 0 4px 6px rgba(75,75,75,.2)

    .container
        padding-right 15px
        padding-left 15px
        margin-right auto
        margin-left auto
        width 100%

        .navbar-brand
            padding-top 15px
            padding-left 15px

            .logo-container
                margin-top -8px !important
                width 125px !important

                .logo-text
                    fill #303e60

        .navbar-collapse
            border-top 0px !important

    .request-demo-btn
        background-color RequestDemoBackgroundColorNoTransparency !important

    .login-btn
        background-color #303e60 !important

    .navbar-header
        float none !important

    .navbar-form
        padding 10px 15px !important
        margin-bottom 0px !important

    .navbar-left,.navbar-right
        float none !important

    .navbar-toggle
        display block !important
        margin-top 8px !important

    .navbar-collapse
        box-shadow inset 0 1px 0 rgba(255,255,255,0.1)
        margin-right -15px !important
        margin-left -15px !important

    .navbar-fixed-top
        top -15px !important

    .navbar-collapse.collapse
        display none !important

    .navbar-nav
        float none !important
        margin-top 0px !important
        margin-bottom 0px !important

    .navbar-nav>li
        float none !important

    .navbar-nav>li>a
        padding-top 10px !important
        padding-bottom 10px !important

    .collapse.in
        display block !important

    .navbar-nav .open .dropdown-menu
        position static !important
        float none !important
        width auto !important
        margin-top 0 !important
        background-color transparent !important
        -webkit-box-shadow none !important
        box-shadow none !important

    .request-demo-btn,
    .login-btn
        display block !important
        width 100% !important
        color white !important
        margin-bottom 10px !important

    .dropdown
        border-bottom 1px solid #e7e7e7 !important
