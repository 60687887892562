@import 'app/static/stylus/DESIGN.styl'
@import 'app/static/stylus/SEARCH_VARIABLES.styl'

.careers-page-main
    font-weight DefaultFontWeight

    .careers-page-full-width
        text-align center
        margin-top 30px

        .careers-page-full-header
            color QuorumBlue
            font-size 40px
            margin-bottom 30px
            margin-top 0px
            font-weight DefaultFontWeight

            @media (max-width: SmallMobileWidth)
                font-size 35px

        .careers-page-full-text
            font-size 25px

            @media (max-width: SmallMobileWidth)
                font-size 20px

    .careers-page-why-quorum
        color QuorumBlue
        background-color white
        text-align center

        .careers-page-full-width
            padding-top 10px
            padding-bottom 30px

        .why-quorum-cta-wrapper
            margin-bottom 10px
            margin-top 5px

        .why-quorum-image-banner
            height 255px
            margin-top 30px

            .why-quorum-image
                width 25%
                height 100%
                display inline-block
                background-size cover
                background-repeat no-repeat

                @media(max-width: 992px)
                    width 50%

                    &:nth-of-type(2n + 0)
                        display none

                @media(max-width: 560px)
                    width 100%

                    &:nth-of-type(1n + 2)
                        display none


    .careers-page-positions
        padding-bottom 10px

        .available-positions-none
            font-size 25px
            text-align center
            margin-bottom 20px
            color QuorumBlue

        .available-positions-main
            margin-right 30px
            margin-left 30px

            .position-thumbnail
                // Default thumbnail color
                background-color QuorumBlue

                &:hover
                    background-color darken(QuorumBlue, 10)

            .position-thumbnail.software-development
                background-color QuorumBlue

                &:hover
                    background-color darken(QuorumBlue, 10)

            .position-thumbnail.customer-success
                background-color LightPurple

                &:hover
                    background-color darken(LightPurple, 10)

            .position-thumbnail.business-development
                background-color DarkPurple

                &:hover
                    background-color darken(DarkPurple, 10)

            .position-thumbnail.operations
                background-color HyperlinkColor

                &:hover
                    background-color darken(HyperlinkColor, 10)

            .position-thumbnail.marketing-and-communications
                background-color MiddlePurple

                &:hover
                    background-color darken(MiddlePurple, 10)

            .position-thumbnail
                min-height 205px
                width 100%
                font-weight 400
                font-size 18px
                margin-bottom 30px
                color white
                display flex
                flex-direction column
                justify-content space-between

                &:hover
                    text-decoration none

                @media(max-width: 992px)
                    min-height 135px

                .position-title
                    margin 20px
                    margin-bottom 5px
                    font-size 22px
                    font-weight 300
                    line-height 1.4
                    overflow hidden
                    display -webkit-box
                    -webkit-line-clamp 4
                    -webkit-box-orient vertical

                    @media (max-width: 992px)
                        -webkit-line-clamp 2

                .position-department
                    font-weight 300
                    margin-right 20px
                    margin-left 20px
                    margin-bottom 20px
                    font-size 18px
                    white-space nowrap
                    text-overflow ellipsis
                    overflow hidden

                    @media (max-width: 1400px)
                        font-size 16px

    .careers-page-benefits
        text-align center
        padding-top 25px
        padding-bottom 30px
        color QuorumBlue
        background-color #f7f7f7

        .careers-page-full-width
            margin-bottom 0px
            margin-top 0px

        hr
            border-top 1px solid QuorumGrey2

        .benefits-pills-wrapper
            display flex
            flex-flow wrap
            margin-left 10px
            justify-content center

        .benefits-image-wrapper
            @media (min-width: 992px)
                margin-left -40px

            .benefits-image
                height calc(655px - 15vw)
                background-size cover
                background-position-y 100%
                background-repeat no-repeat
                margin-bottom 15px

                @media (max-width: 1199px)
                    display none

    .careers-page-facilities
        background-color LightPurple
        display flex
        margin-left 0px

        @media (max-width: 1270px)
            flex-direction column
            margin-right 0px

        .facilities-right-section
            flex 0 0 50%

            .facilities-text-section
                display flex
                color white
                text-align right
                position relative
                top 50%
                transform translateY(-50%)

                @media (max-width: 1270px)
                    text-align center
                    transform none
                    // Needed for Firefox browsers
                    top 0%

                .facilities-text
                    margin 50px

                    @media (max-width: SmallMobileWidth)
                        margin 30px

                    .facilities-header
                        font-size 40px
                        margin-bottom 30px

                        @media (max-width: SmallMobileWidth)
                            font-size 35px
                            margin-bottom 15px

                    .facilities-description
                        font-size 25px

                        @media (max-width: SmallMobileWidth)
                            font-size 20px

    .careers-page-join-team
        text-align center
        padding-top 15px
        padding-bottom 55px
        background-color QuorumBlue
        color white

        .careers-page-full-width
            margin-bottom 40px

            @media (max-width: SmallMobileWidth)
                padding 0px

    .careers-page-cta-button
        white-space normal
        font-weight DefaultFontWeight
        font-size 22px
        padding 10px 20px
        width 500px
        border-color white
        color white !important
        border-radius 5px

        background QuorumBlue

        &:hover
            background lighten(QuorumBlue, 10%)

        @media (max-width: MobileWidth)
            width 100%
            margin 0px !important
