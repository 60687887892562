.press-page
    .press-page-content
        padding-top 75px
        padding-bottom 75px

        .press-inline

            @media (min-width:768px)
                min-height 290px

            @media (max-width:768px)
                margin-bottom 20px

            .blue-image
                .well
                    height 100px

                .publication-image
                    display inline-block
                    height 100%
                    vertical-align middle
                    width 100%
                    background-size contain
                    background-repeat no-repeat
                    background-position center

            .press-main-header
                color #303e60
                font-weight 300
                margin-top 0px
                margin-bottom 20px
                margin-top 20px
                font-size 40px
