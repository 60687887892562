@import '../../../../../app/static/stylus/DESIGN.styl'
@import '../../../../../app/static/stylus/SweetAlertTwo.styl'

.marketing-frame
    width 100%
    margin 0px !important
    padding 0px !important

    .marketing-frame-children
        width 100%
        padding 0px !important
        overflow-x hidden

    .btn-lg
        font-weight 300

    .btn
        &:focus
            outline none
            outline-color initial
            outline-style none
            outline-width initial

// global rules for the entire marketing site
body
    // Hubspot's Cookie Policy javascript injection sometimes has a bug where the 'body' element
    // has an inline style of padding-top: XXpx added to it.
    // This will override any inline styles arbitrarily added to the body element via JavaScript
    padding 0px !important

    nav .container

        @media (min-width: NavBarBreakPoint)
            padding-left 0px
            padding-right 0px
            width 82%

    #subscribe
        position relative
        top -75px

    // rules for the Hubspot Cookie notification
    div#hs-eu-cookie-confirmation
        div#hs-eu-cookie-confirmation-inner
            max-width 100%!important
            padding 10px!important
            display flex
            align-items center
            div#hs-en-cookie-confirmation-buttons-area
                margin 0!important
            a#hs-eu-confirmation-button
                margin-right 0px!important
                margin-left 10px!important
                font-size 14px
                padding 10px 14px!important
            p
                flex-grow 1
                margin 0px!important
                align-items center
                font-size 14px!important

    @media screen and (max-width: 480px)
        div#hs-eu-cookie-confirmation
            font-size 12px !important
            div#hs-eu-cookie-confirmation-inner
                padding 10px !important
                a
                    font-size 12px !important
                a#hs-eu-confirmation-button
                    font-size 14px !important
                p
                    font-size 12px !important
                    margin-bottom 0px !important
                    line-height 15px !important

    .tooltip-inner
        font-size 17px!important
        color: #303e60!important
        background: #fff;
        border: 1px solid #303e60!important

    .tooltip-arrow
        border-bottom-color #303e60!important

    .form-group-lg
        .form-control
            height 56px

    .modal-body
        padding 0px !important

    .modal-dialog
        margin 60px auto

        @media (min-width: 768px)
            width 768px

        @media (max-width: 768px)
            width 90%
