@import DESIGN

TypeFormHeight = 350px

.blog-page
    .email-request-form
        padding 0px 13px
        margin-top 40px
        margin-bottom 15px

        .email-signup-header
            margin-top 0px
            margin-bottom 35px
            color QuorumBlue
            font-weight 300
            font-size 35px
            text-align center

    .blog-detail-byline-date
        font-size 14px
        margin-top 30px
        color StandardGrey

    .blog-detail-byline-author
        font-size 20px
        margin-top -5px
        margin-bottom 30px
        font-weight 300

    .blog-detail-content-wrapper
        position relative

        .blog-detail-content
            padding-top 50px
            margin-bottom 5px

            font-family 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif !important

            .note-video-clip
            // This is the classname summernote gives to embedded videos
                width 100%

                @media (min-width: MobileWidth)
                    padding-right 20%
                    padding-left 20%

            img
                width 100%
                height auto !important

            p
                color black
                font-size 2.3rem
                font-weight 200
                line-height 1.6

            h1, h1 > p
                font-size 44px
                font-weight 300
                color QuorumBlue
                line-height 56px
                padding-top 20px

            h2, h2 > p
                font-size 40px
                font-weight 300
                color QuorumBlue
                line-height 50px
                padding-top 20px

            h3, h3 > p
                font-size 35px
                font-weight 300
                color QuorumBlue
                line-height 44px
                padding-top 20px

            blockquote
                font-size 2.1rem
                background QuorumGrey1
                border-left 10px solid QuorumGrey5
                padding 10px 20px
                quotes "\201C""\201D""\2018""\2019"

                &:before
                    color QuorumGrey5
                    content open-quote
                    font-size 4em
                    line-height 0.1em
                    margin-right 0.15em
                    vertical-align -0.4em

            h1, h3, p, blockquote
                margin-top 0px
                margin-bottom 30px

            twitterwidget
                margin auto

            .typeform-widget
                width 100%
                height TypeFormHeight

                & > div
                    height TypeFormHeight

                    :last-child
                        height TypeFormHeight

                iframe
                    width 100%
                    height 100%


        .blog-detail-hubspot-cta
            margin-bottom 30px

    .blog-detail-related-content-wrapper
        background-color #d5d9df

        .blog-detail-related-content
            margin-bottom 40px

            .related-content-header
                margin-top 20px
                margin-bottom 20px
                color QuorumBlue
                font-weight 300
                font-size 35px
                text-align center
