@import '../../../../../app/static/stylus/DESIGN.styl'

FeaturedArticleHeight = 450px
HoverColor = #b4bcc2

.featured-article-section
    .feature-article-backround-transparency
        width 100%
        height FeaturedArticleHeight
        position absolute
        background rgba(48,62,96,0.5)

    .section
        height FeaturedArticleHeight !important

        @media (max-width: SmallMobileWidth)
            padding-top 30px
            padding-bottom 30px


        .section-text
            text-align left

            &.align-right
                text-align right

            @media (min-width: SmallMobileWidth)
                vertical-align bottom !important

        .featured-article-lead-header
            color white
            font-weight 300
            font-size 24px

        .featured-products-lead-header
            font-size 20px
            margin-bottom 30px

        .section-main-header
            color white
            margin-top 10px

        .read-more, .products-wrapper
            a
                color #ffffff

                &:hover,
                &:focus
                    color HoverColor

        .products-wrapper
            color #ffffff
            margin-top 20px
            font-weight 300
            font-size 20px

            @media (max-width: SmallMobileWidth)
                display flex
                flex-direction column
                text-align center

            .product-button
                border 1px solid white
                padding 10px
                margin-right 10px
                margin-bottom 10px
                border-radius 5px
                font-size 22px

                &:hover
                    border-color HoverColor

            .product-stakeholder
                display inline
