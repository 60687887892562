@import DESIGN

.search-request-demo-main
    border-radius 4px
    padding-top 9px
    padding-bottom 30px
    margin-left 0px

    @media (min-width: MobileWidth)
        float right
        background-color rgba(170, 170, 170, 0.2)
        box-shadow 0 2px 5px rgba(25,25,25,.5)

    .form-group
        text-align left

    .search-request-demo-text
        padding-bottom 0px
        color white

        h1, h3
            font-weight 300

        .search-request-demo-main-header
            font-size 30px
            margin-bottom 25px
            font-weight 350 !important

            @media (max-width: SmallMobileWidth)
                font-size 24px

        .search-request-demo-text-main
            font-size 25px
            font-weight 300
            padding-bottom 15px

            @media (max-width: SmallMobileWidth)
                font-size 20px

    .search-request-demo-form-column
        border-top 1px solid #eee
        padding-top 30px
        padding-left 0px
        padding-right 0px

        .search-request-demo-submit
            margin-top 15px
            display inline-block
            background-color RequestDemoPurple
            border-color white
            color white
            font-weight 400
            font-size 22px

            &:hover
                background-color RequestDemoHoverPurple

        .search-request-demo-form
            // each of the inputs is a col-md-x
            // that is wrapped in a div
            // we need to select the children and
            // then make sure they have appropriate
            // padding in both two column
            // and one column orientations
            div
                div[class^="col-"]
                    padding 0px

                &:nth-child(odd)
                    div[class^="col-"]
                        padding-right 10px

                &:nth-child(even)
                    div[class^="col-"]
                        padding-left 10px

                &:last-child
                    div[class^="col-"]
                        padding 0px !important

            textarea
                resize none

            .form-control
                border-top-right-radius 4px !important
                border-bottom-right-radius 4px !important

            .input-group-addon
                background-color #3d3262

                i
                    color white
