@keyframes fade-in-from-right 
    from
        padding-left 100px
        opacity 0

    to
        padding-left 0px
        opacity 1

// .fade-in-from-right
//     animation-duration 1s
//     animation-name fade-in-from-right
