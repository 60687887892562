@import '../../../../../app/static/stylus/DESIGN.styl'

.about-us-hero
    height 300px
    min-height 300px

.about-us-header-image
    font-size 28px !important
    margin-bottom 0px !important

.about-us-biography-header
    background-color QuorumBlueRGBA(.9)
    padding-top 30px
    padding-bottom 30px
    text-align center
    color white
    font-size 35px
    font-weight 300
    margin-top 0px
    margin-bottom 20px

.about-us-biography-main
    padding-bottom 20px

    .about-us-biography-row
        margin-bottom 30px
        display flex
        align-items center

        @media (max-width: MobileWidth)
            flex-direction column

        .about-us-text-section
            .section-text-main
                @media (max-width: MobileWidth)
                    font-size 18px

        .about-us-picture-section
            .about-us-bio-image
                border-radius 50%
                height 330px

                @media (max-width: 1350px)
                    height 275px

                @media (max-width: MobileWidth)
                    height 175px

        .picture-section-mobile
            display flex
            justify-content center

        .text-aligned-right, .image-aligned-right
            @media (min-width: MobileWidth)
                text-align right
