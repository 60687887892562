@import '../../../../../app/static/stylus/DESIGN.styl'

DefaultFontSize = 18px
DefaultFontWeight = 300

.login-page-main
    background-color Violet0
    text-align center
    height 100dvh

    a {
        text-decoration-line: underline;
    }

    .login-page-main-container-tint
        background rgba(QuorumBlue, .4)

    .login-page-main-container
        display flex
        flex-direction column
        height: 100%
        overflow auto

        .login-page-centered
            @media (min-width: LoginMobileWidth)
                // Desktop screens, center content in middle of page
                position fixed
                top 50%
                left 50%
                transform: translate(-50%, -50%)

            @media (max-width: LoginMobileWidth)
                // Mobile screen, content at top of the page
                transform: translate(0%, 5%)

            .login-page-content
                @media (min-width: LoginMobileWidth)
                    // Desktop screen
                    display flex

        .login-page-footer
            position absolute
            bottom 0px
            text-align center
            width 100%
            font-weight DefaultFontWeight
            font-size DefaultFontSize
            color white
            padding 20px

            @media (max-width: LoginMobileWidth)
                // Mobile screen, hide footer positioned absolute at the bottom of the screen
                display none

            a
                color white

                &:hover
                    color QuorumGrey2

        .login-page-footer-mobile
            font-weight DefaultFontWeight
            text-align center
            font-size DefaultFontSize
            color white
            margin 20px
            padding 15px

            @media (min-width: LoginMobileWidth)
                // Desktop screens, hide footer directly underneath form
                display none

            a
                color white

                &:hover
                    color QuorumGrey2
