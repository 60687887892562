@import '../../../../../app/static/stylus/DESIGN.styl'

.latest-insights-main
    background-color #d5d9df

    .latest-insights-header
        margin-top 30px
        margin-bottom 5px
        font-size 42px
        color QuorumBlue
        font-weight 300
        text-align center

        .latest-insights-header-text
            font-weight 300

        .latest-insights-cta-large, .latest-insights-cta-small
            font-family "Helvetica Neue", Helvetica, Arial, sans-serif
            color MiddlePurple
            font-size 24px
            text-indent 10px
            cursor pointer

            &:hover
                color lighten(MiddlePurple, 20%)

            .fa
                normal normal normal 14px/1 FontAwesome
                font-size 20px
                margin-top 8px
                float right

        .latest-insights-cta-large
            right 28px
            bottom 10px
            position absolute

            @media screen and (max-width: 991px)
                visibility hidden

        .latest-insights-cta-small
            margin-top 5px

            .fa
                float none

    .latest-insights-content
        margin-bottom 30px

    .email-request-form
        margin-top 10px
        margin-bottom 50px
        font-weight 300
