@import '../../../../../app/static/stylus/DESIGN.styl'

.footer-wrapper
    background rgba(48, 62, 96, 0.2)

    hr
        margin-top 0px
        margin-bottom 0px

    .footer
        padding-top 30px
        padding-bottom 30px

        &.row
            margin-left 0px
            margin-right 0px

            .footerLink
                display block

        .footer-text
            font-size 16px
            color #0e121c
            font-weight 300

        .list-unstyled
            li
                font-size 16px
                font-weight 300
                margin-bottom 3px

                a
                    margin-right 7px
                    color rgb(48, 62, 96)
                    font-weight 300

                i
                    color #303e60

    .sub-footer
        display flex
        flex-direction row
        flex-wrap wrap
        justify-content flex-start
        align-items flex-start
        background-color #303e60
        padding-top 20px
        padding-bottom 20px

        .sub-footer-logo-container
            min-width 160px
            max-width 200px
            margin-left auto
            margin-right auto

        .sub-footer-text
            text-align left
            margin 15px 0
            color white !important
            font-size 16px
            font-weight 300

            .sub-footer-column-header
                padding-bottom 10px
                border-bottom 1px solid white
                margin-bottom 10px

            a
                color white !important

        .sub-footer-copyright
            text-align center

        .sub-footer-disclaimer
            text-align center
            font-size 14px
            color #d5d9df !important

        .sub-footer-col
            justify-content center
            display flex !important
            flex-direction column

