@import "Animations.styl"

.visible-section-wrapper
    hr
        width 100%
        margin-bottom 0px
        margin-top 0px

    .btn
        transition background-color 0.2s ease-out
        -o-transition background-color .2s ease-out
        -ms-transition background-color .2s ease-out
        -moz-transition background-color .2s ease-out
        -webkit-transition background-color .2s ease-out

    a
        transition color 0.2s ease-out, border-bottom 1s ease-in
        -o-transition color .2s ease-out, border-bottom 1s ease-in
        -ms-transition color .2s ease-out, border-bottom 1s ease-in
        -moz-transition color .2s ease-out, border-bottom 1s ease-in
        -webkit-transition color .2s ease-out, border-bottom 1s ease-in

        &:hover,
        &:focus
            text-decoration none !important

.section-holder
    text-align center
    width 100%

    margin-left 0px !important
    margin-right 0px !important

    &.section-grey
        background-color #f7f7f7 !important

    &.section-purple
        background-color rgba(133, 78, 164, 0.9) !important

    &.section-blue
        background-color rgba(48, 62, 96, 0.9) !important

    &.section-purple,
    &.section-blue
        h1, h2, h3, h4, span, p
            color white !important

    @media (max-width: 991px)
        // mobile and tablet specifc
        &
            text-align center !important

    .row
        margin-right 0px
        margin-left 0px

    .condensed-text
        font-weight 300
        letter-spacing -1px

    .section
        padding-top 40px
        padding-bottom 40px
        display table

        .custom-btn-toolbar-more-padding
            padding-top 40px

        .custom-btn-toolbar-less-padding
            padding-top 20px

        @media (min-width: 992px)
            // Non-Mobile Specific
            height 450px

        @media (max-width: 991px)
            // Mobile and Tablet Specific
            &.section-left
                padding-bottom 15px !important

            &.section-right
                padding-top 15px !important

        .section-text
        .section-product
            display table-cell
            vertical-align middle
            margin-bottom 20px

.section-main-header
    color #303e60
    font-weight 300
    margin-top 0px !important
    margin-bottom 15px !important
    font-size 42px

    a
        color #303e60 !important

        &:hover,
        &:focus
            color #7b8a8b !important

.section-lead-header
    // font-weight 300
    padding-top 0px
    font-size 20px
    color #714987

.section-text-main
    font-weight 200
    font-size 2.1rem

.condensed-header
    font-size 23px
    color #7b8a8b
    text-transform uppercase
    font-weight 300
    letter-spacing -1px
