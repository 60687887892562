@import '../../../../../app/static/stylus/DESIGN.styl'

MobileWidthSmall = 480px

.social-media-sidebar-main
    display flex
    font-size 16px
    left 45px
    top 35%
    margin-top 5px
    z-index 1

    &.top
        position absolute
        top 48px
        left calc(-90px + 15vw)
        display flex
        flex-flow column

        @media (max-width: MobileWidth)
            display none

    &.bottom
        flex-flow row
        width 100%
        justify-content center
        position static
        padding-bottom 30px
        padding-left 4px
        margin-right 30px

        .social-button
            @media (min-width: MobileWidth)
                margin-right 30px

    @media (max-width: MobileWidthSmall)
        font-size 14px

    .social-button
        display inline-block
        padding 10px
        border-radius 5px
        text-align center
        margin-top 5px
        margin-bottom 5px
        color white

        @media (max-width: MobileWidth)
            margin-right 20px

        @media (max-width: MobileWidthSmall)
            margin-right 5px

        .fa
            font-size 20px
            margin-right 10px

            @media (max-width: MobileWidthSmall)
                font-size 18px

        &:hover, &:focus, &:active
            text-decoration none

    .marketing-social-twitter
        background-color TwitterLightBlue

        &:hover
            background-color darken(TwitterLightBlue, 5)

    .marketing-social-linkedin
        background-color LinkedInBlue

        &:hover
            background-color darken(LinkedInBlue, 5)

    .marketing-social-facebook
        background-color FacebookBlue

        &:hover
            background-color darken(FacebookBlue, 5)

    .marketing-social-email
        background-color QuorumBlue

        &:hover
            background-color darken(QuorumBlue, 5)
