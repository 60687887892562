.hero-image-bottom-banner
    width 100%
    height 90px
    background-color rgba(236,240,241,0.75)
    position absolute
    left 0
    bottom 0
    display table

    .hero-image-bottom-banner-centered
        display table-cell
        vertical-align middle

    .client-logos-desktop
        width 90%
        padding 10px
        padding-bottom 16px
        max-width 1200px

    .client-logos-mobile
        width 90%
        max-width 480px
        padding 10px