@import DESIGN

.request-demo-wrapper
    padding-bottom 15px
    padding-top 15px
    background-color transparent

    .old-request-form-ie10
        margin-top 15px
        margin-bottom 15px

    &.experiment
        padding 0px

        .request-demo-header
            color white
            font-size 35px
            font-weight 300
            padding-top 20px

            @media (max-width: 950px)
                font-size: 25px

        .section-holder .section
            height 310px

            @media (min-width: 1278px)
                height 275px

        .request-demo-button
            background #8a3ab7
            color white
            font-size 30px
            font-weight 400
            border-width 1px
            border-color white
            margin-top 30px
            padding-left 20px
            padding-right 20px

            &:hover
                background #854ea4
                color white
            &:active
                border-color #f2f1f3
                color white

    hr
        display none
