@import DESIGN

.picture-thumbnail-main
    a
        text-decoration none
        cursor pointer

        &:hover
            text-decoration none

        .picture-thumbnail-image
            min-height 370px
            max-height 370px
            width auto
            background-size cover
            background-color StandardGrey
            background-blend-mode multiply
            margin 15px 0px

            &:hover
                background-color #333

            .picture-thumbnail-type
                padding 20px 30px 20px 20px
                color white
                font-size 20px
                font-weight 300
                text-align left
                text-overflow ellipsis
                white-space nowrap
                overflow hidden

            .picture-thumbnail-title
                text-align left
                margin 0px 20px
                color white
                font-size 28px
                font-weight 300
                line-height 1.4

            .picture-thumbnail-logo
                position absolute
                bottom 30px
                right 30px
                height 38px
                width 70%
                background-size contain
                background-repeat no-repeat
                background-position-x right
                background-position-y bottom

            .picture-thumbnail-length
                position absolute
                bottom 30px
                right 30px
                color white
                font-weight DefaultFontWeight
                font-size 20px
