@import DESIGN

.blue-section-main
    .section-holder .section
        height 245px
        display flex
        align-items center

        @media (min-width: 1278px)
            height 215px

        .blue-section-text
            color white
            font-size 30px
            font-weight 300

            @media (max-width: 950px)
                font-size 25px

            a
                color white
                text-decoration underline

                &:hover, &:focus, &:active
                    color QuorumGrey5
                    text-decoration underline !important
