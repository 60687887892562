@import '../../../../../app/static/stylus/DESIGN.styl'

.blog-download-main
    .hero-img
        height 350px

        .hero-img-container
            .hero-img-centered-content
                padding-top 60px

    .blog-download-content
        position relative
        margin 25px 0px

        .blog-download-text
            margin-top 0px

            .section-text-main
                font-size 19px

                @media (max-width: 992px)
                    font-size 18px

            twitterwidget
                margin auto

            .blog-download-hubspot-cta
                margin-top 40px

                @media (max-width: 992px)
                    margin-top 20px

        .blog-download-text li
            list-style none

            span
                font-size 22px !important

        .blog-download-text li:before
            position relative
            top 2px
            font-size 25px
            color DarkPurple
            font-family 'FontAwesome'
            content "\f05d"
            margin-right 15px

        .blog-download-description
            font-weight 300
            color QuorumBlue
            font-size 22px
            padding-bottom 30px
            padding-top 47px

            @media (max-width: 992px)
                font-size 20px
                padding-top 0px
                padding-bottom 0px

        ul
            padding-left 0px !important

            li
                padding-left 0px !important

        .blog-download-form-component
            padding 0px

    .social-media-sidebar-main
        left calc(-95px + 8vw)
        top 43px

        &.top
            @media (max-width: 1200px)
                display none

        &.bottom
            padding-bottom 0px
            margin-top -30px

            @media (min-width: 1200px)
                display none

