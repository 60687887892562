@import '../../../../../app/static/stylus/DESIGN.styl'
@import "Animations.styl"

.product-show-section-wrapper
    .section
        .section-text
            .section-main-header
                .section-main-header-link
                    color inherit !important

                    &:hover
                        text-decoration underline !important

            .section-lead-header
                font-size 24px
                font-weight 400

            .section-text-main
                margin-top 15px


    .product-img-holder
        display table-cell
        vertical-align middle

        .product-img
            display block
            margin-right auto
            margin-left auto
            max-width 100%
            height auto

        .product-video-wrapper
            background-size contain
            background-repeat no-repeat
            background-position center
            min-height 712px

            margin-right -25px
            margin-left -25px

            .mobile-video
                height 539px
                position relative
                top 86px

            .mobile-xr-video
                border-radius 30px
                -webkit-border-radius 30px
                -o-border-radius 30px
                position relative
                overflow hidden
                width 295px
                height 631px
                top 38px
                right 2px
                display inline-block

            iframe
                height 100%
                border 0px
                margin 0px
                padding 0px

    .product-video-holder
        text-align center

    .product-show-section-row
        background-color #ffffff

        @media (min-width: MobileWidth)
            display flex
            align-items center

        .product-show-button
            white-space normal
            margin-top 5px
            font-size 22px

            a
                color #303e60

            background #303e60
            border-color white
            color white !important

            &:hover
                background lighten(#303e60, 10%)

            @media (max-width: MobileWidth)
                width 100%
                margin 0px !important

        &.text-aligned-left
            text-align left

        &.text-aligned-right
            text-align right

        .product-show-video
            @media (min-width: MobileWidth)
                height 636px
