.as-featured-in
    cursor pointer

    .as-featured-in-section-holder
        background-color white
        padding-top 20px
        padding-bottom 20px

        .as-featured-in-column
            padding-top 30px
            padding-bottom 35px
            position relative

            .as-featured-in-text
                color #7b8a8b
                font-size 23px
                margin-bottom 20px
                margin-top 0px

            .as-featured-in-img-wrapper
                .as-featured-in-desktop-img
                    max-width 100%

                .as-featured-in-mobile-img
                    max-width 100%
                    margin-bottom 20px

                .as-featured-in-mobile-img-last
                    margin-bottom 0px !important
