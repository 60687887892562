@import DESIGN

MobileFormWidth = 419px

.scheduling-form-main
    text-align center

    .meetings-iframe-container
        width 100%
        height 612px
        position relative
        overflow-y hidden

        @media (max-width: MobileFormWidth)
            height 600px

        iframe
            height 666px
            position relative

            @media (min-width: SmallMobileWidth)
                bottom 43px

            @media (max-width: 703px)
                top 5px

            &.meeting-iframe-submitted
                height 430px
                bottom 0px
                top 30px

    .scheduling-product-button
        color white !important
        background-color QuorumBlue !important
        font-weight DefaultFontWeight
        font-size 24px
        margin-top 67px

        @media (max-width: MobileWidth)
            margin-top 25px

        &:hover
            background-color darken(QuorumBlue, 10) !important

        &:focus, &:active, &:focus:active
            outline none

