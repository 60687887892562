.quote-section-main
    border-top 1px solid #e2e2e2
    padding-top 15px
    margin-top 15px
    display flex

    @media (max-width: 992px)
        flex-direction column

    .quote-section-column
        display flex
        flex-direction column
        justify-content center
        padding 0px

    .author-column
        display flex

        @media (max-width: 992px)
            margin-top 5px

        .quote-section-logo
            width 200px
            height auto

        .quote-section-author
            margin-bottom 0px
            font-size 22px

        .quote-section-author-title
            font-size 15px
            margin-bottom 0px

        .quote-section-author, .quote-section-author-title
            margin-top 7px !important

    .quote-section-text
        margin-top 0px !important

    .column-aligned-right
        align-items center

        @media (min-width: 992px)
            align-items flex-end !important
            text-align right

    .column-aligned-left
        align-items center

        @media (min-width: 992px)
            align-items flex-start !important
            text-align left
