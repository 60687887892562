@import '../../../../../app/static/stylus/DESIGN.styl'

.blog-download-form-wrapper
    padding-bottom 30px
    padding-top 57px

    @media (max-width:767px)
        padding-top 15px

    hr
        display none

    .blog-download-main-header
        font-size 30px
        font-weight 300
        color QuorumBlue

        @media (max-width: 992px)
            font-size 25px

    .blog-download-subheader
        font-size 20px
        font-weight 300
        padding-bottom 15px
        color QuorumBlue

        @media (max-width: 992px)
            font-size 18px

    .blog-download-form-column
        background-color #fff
        border 1px solid #f2f1f3
        border-radius 4px
        box-shadow 0 2px 5px rgba(25,25,25,.2)
        padding-top 9px
        padding-bottom 30px

        .blog-download-form-submitted
            padding-top 15px

            .section-main-header
                font-size 37px
                margin-bottom 10px
                margin-top 0px

            .section-text-main
                margin-bottom 0px

                a
                    &:hover
                        cursor pointer
                        text-decoration underline !important

        .form-group
            text-align left

        .blog-download-form-text
            padding-bottom 0px

            .section-main-header
                margin-bottom 20px
                color #303e60

            .section-lead-header
                color #854EA4

            .section-text-main
                font-weight 300
                color #000
                text-align center
                font-size 22px

        .blog-download-form-col
            border-top 1px solid #eee
            padding-top 30px
            padding-left 0px
            padding-right 0px

            .blog-download-signup-button
                display inline-block
                position relative
                width 100%
                padding 7px
                text-align left
                padding-left 55px
                background-color white
                border-radius 4px
                cursor pointer
                color QuorumBlue
                font-weight 400
                border 1px solid QuorumGrey5
                user-select none

                &:hover
                    background-color QuorumGrey5

                .fa
                    position absolute
                    left 14px
                    font-size 20px
                    top 8px

            .blog-download-form-submit
                margin-top 15px
                display inline-block
                background-color HyperlinkColor
                color white

                &:hover
                    background-color darken(HyperlinkColor, 20%)

            .blog-download-form-inputs

                // each of the inputs is a col-md-x
                // that is wrapped in a div
                // we need to select the children and
                // then make sure they have appropriate
                // padding in both two column
                // and one column orientations
                div
                    div[class^="col-"]
                        padding 0px

                @media (min-width 992px)
                    div

                        &:nth-child(odd)
                            div[class^="col-"]
                                padding-right 10px

                        &:nth-child(even)
                            div[class^="col-"]
                                padding-left 10px

                        &:last-child
                            div[class^="col-"]
                                padding 0px !important

                    .conditional-fields
                        // Conditional fields have a different 'last-child', which requires slightly different styling
                        div
                            &:last-child
                                div[class^="col-"]
                                    padding-left 10px !important

                textarea
                    resize none

                .form-control
                    border-top-right-radius 4px !important
                    border-bottom-right-radius 4px !important

                .input-group-addon
                    background-color #3d3262

                    i
                        color white
