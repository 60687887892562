@import '../../../../../app/static/stylus/DESIGN.styl'

.pills-wrapper
    display flex
    flex-flow wrap
    margin-left 10px
    justify-content center

    .pill-main
        padding 15px
        font-weight 400
        border 1px solid QuorumBlue
        background-color white
        display flex
        font-size 14px
        border-radius 5px
        margin-bottom 20px
        margin-right 20px
        width 46%
        text-align center
        height auto

        @media(max-width: 666px)
            width 95%

        .pill-icon
            display flex
            align-items center
            float left
            padding-right 15px
            border-right 1px solid black
            margin-right 15px
            margin-left 5px

            .fa
                font-size 40px
                width 50px

        .pill-title
            font-size 25px
            margin-bottom 5px
            font-weight 300

            @media (max-width: SmallMobileWidth)
                font-size 22px

        .pill-description
            font-size 18px
            font-weight 300

            @media (max-width: SmallMobileWidth)
                font-size 16px
