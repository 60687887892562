.home-page
    width 100%

    .home-page-hero-image
        height 525px

        @media (max-width: 600px)
            height calc(100vh + 20px)
            max-height 850px

        .hero-img-centered-content
            padding-top 0px

    .hero-img
        max-height 625px !important

        .hero-img-container
            background: rgba(48,62,96,0.8) !important

    .section-text-main
        font-size 25px

    .product-show-section-wrapper
        .product-img-holder
            .product-img
                max-height 450px

    .section-holder
        .section
            @media only screen and (min-width: 992px)
                padding-top 15px
                padding-bottom 15px

    #legislative-tracking
        background #d5d9df !important

        h2
            color #303e60 !important

        p
            color #333333 !important
